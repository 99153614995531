// Generated by Framer (b0998f3)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-NRGa9"

const variantClassNames = {nK14DlP1P: "framer-v-dhuc8p"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "nK14DlP1P", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-dhuc8p", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"nK14DlP1P"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1td654d"} data-framer-name={"Youtube"} fill={"var(--token-1b444440-6e98-401c-93cc-651f6c08f890, rgb(20, 20, 31)) /* {\"name\":\"primary-black\"} */"} intrinsicHeight={24} intrinsicWidth={24} layoutDependency={layoutDependency} layoutId={"DhpoBbplS"} svg={"<svg width=\"24\" height=\"24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M23.474 5.926C24 7.77 24 11.718 24 11.718s0 3.905-.526 5.792a2.927 2.927 0 0 1-2.107 2.106c-1.886.482-9.345.482-9.345.482s-7.503 0-9.39-.482A2.926 2.926 0 0 1 .528 17.51C0 15.623 0 11.718 0 11.718s0-3.949.527-5.792a2.984 2.984 0 0 1 2.106-2.15c1.886-.526 9.389-.526 9.389-.526s7.459 0 9.345.527c1.01.263 1.843 1.096 2.107 2.15Zm-13.91 9.346 6.231-3.554-6.23-3.554v7.108Z\" fill=\"#14141F\"/></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-NRGa9.framer-f1qwbe, .framer-NRGa9 .framer-f1qwbe { display: block; }", ".framer-NRGa9.framer-dhuc8p { height: 24px; overflow: hidden; position: relative; width: 24px; }", ".framer-NRGa9 .framer-1td654d { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 24px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerfljIuXg8H: React.ComponentType<Props> = withCSS(Component, css, "framer-NRGa9") as typeof Component;
export default FramerfljIuXg8H;

FramerfljIuXg8H.displayName = "ic-24px-youtube";

FramerfljIuXg8H.defaultProps = {height: 24, width: 24};

addFonts(FramerfljIuXg8H, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})